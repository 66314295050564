










import Vue from 'vue';
import Component from 'vue-class-component';
import CreateUserForm from "@/context/user/components/form/CreateUserForm.vue";

@Component({
  components: {CreateUserForm}
})
export default class CreateUserPage extends Vue {

}
