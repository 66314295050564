import { CreateUserCmd } from "@/context/user/model/CreateUserCmd";
import { axiosInstance } from "@/plugins/axios";
import { Nullable } from "@/models/CustomTypes";

const baserUrl = "/api/users";

export async function create(cmd: CreateUserCmd) {
  const response = await axiosInstance.post(baserUrl + "/create", cmd);

  return response;
}

export async function validateUniqueUsername(username: Nullable<string>) {
  const response = await axiosInstance.get(baserUrl + "/validate/unique?username=" + username);

  return response;
}

export const USER_API = {
  baserUrl,
  create,
  validateUniqueUsername
};
