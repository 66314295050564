import { ClientAutocompleteType, Nullable } from "@/models/CustomTypes";
import { CreateUserCmd } from "@/context/user/model/CreateUserCmd";

export class CreateUserFormModel {
  username: Nullable<string> = "";

  password: Nullable<string> = "";

  passwordConfirmation: Nullable<string> = "";

  first_name: Nullable<string> = "";

  last_name: Nullable<string> = "";

  email: Nullable<string> = "";

  clients: Nullable<Array<Nullable<ClientAutocompleteType>>> = new Array<ClientAutocompleteType>();

  constructor(
    username?: Nullable<string>,
    password?: Nullable<string>,
    passwordConfirmation?: Nullable<string>,
    first_name?: Nullable<string>,
    last_name?: Nullable<string>,
    email?: Nullable<string>,
    clients?: Nullable<Array<Nullable<ClientAutocompleteType>>>
  ) {
    this.username = username;
    this.password = password;
    this.passwordConfirmation = passwordConfirmation;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.clients = clients;
  }

  public toCmd(): CreateUserCmd {
    const cmd: CreateUserCmd = new CreateUserCmd();
    cmd.username = this.username ? this.username : "";
    cmd.first_name = this.first_name ? this.first_name : "";
    cmd.last_name = this.last_name ? this.last_name : "";
    cmd.email = this.email ? this.email : "";
    cmd.password = this.password ? this.password : "";
    // sprawdzic czy uproszczony != null && != undefined nie zadziala
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    cmd.clients = this.clients
      ? this.clients
          .filter(client => client != null && client !== undefined)
          .map(client => client.id)
      : new Array<number>();

    return cmd;
  }
}
