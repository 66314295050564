




































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {CreateUserFormModel} from "@/context/user/model/CreateUserFormModel";
import {Nullable, ValidationFunctionType, VForm, VTextField} from "@/models/CustomTypes";
import {emailRule, required} from "@/context/recommendations/utils/validations";
import {CreateUserCmd} from "@/context/user/model/CreateUserCmd";
import {USER_API} from "@/api/UserApi";
import ClientAutocomplete from "@/components/form-controls/ClientAutocomplete.vue";
import {ToastOptions} from "vue-toasted";
import {Ref, Watch} from "vue-property-decorator";
import {AxiosResponse} from "axios";

@Component({
  components: {ClientAutocomplete}
})
export default class CreateUserForm extends Vue {

  @Ref("form")
  readonly form!: VForm;
  @Ref("inputUsername")
  readonly inputUsername!: VTextField;
  model: CreateUserFormModel = new CreateUserFormModel();
  formHasErrors: boolean = false;
  submitting: boolean = false;
  usernameRules: Array<ValidationFunctionType | any> = [required];
  firstNameRules: Array<ValidationFunctionType> = [required];
  lastNameRules: Array<ValidationFunctionType> = [required];
  emailRules: Array<ValidationFunctionType | ((v: string) => true | string)> = [required, emailRule];
  passwordRules: [ValidationFunctionType] = [required];
  passwordConfirmationRules: Array<ValidationFunctionType> = [required, (() => {
    return this.model.password === this.model.passwordConfirmation || 'Hasła nie są ze sobą zgodne.'
  })];
  clientsRules: Array<ValidationFunctionType> = [];
  showPassword: boolean = false;
  validation = {username: ""};
  randomPassword: boolean = true;

  @Watch('model.password', {})
  onPasswordChange() {
    this.model.passwordConfirmation = ""
  }

  @Watch('randomPassword', {})
  onRandomPasswordChange() {
    this.model.passwordConfirmation = ""
    this.model.password = ""
  }

  async validate() {
    const frontedResult: boolean = this.form.validate()
    const backendResult: boolean = await this.validateUniqueUsername(this.model.username)
    return frontedResult && backendResult;
  }

  reset(): void {
    this.validation.username = ""
    this.form.reset()
  }

  resetForm(): void {
    this.reset()
  }

  goBack(): void {
    this.$router.go(-1)
  }

  async submit() {
    const validation: boolean = await this.validate()
    if (validation) {
      this.submitting = true;
      try {
        const cmd: CreateUserCmd = this.model.toCmd();
        await USER_API.create(cmd);
        let options: ToastOptions = {
          type: "error",
          icon: "mdi-alert-circle",
          duration: 5 * 1000,
          position: "top-right"
        };
        this.$toasted.success("Użytkownik został zapisany.", options);
      } catch (error) {
        let options: ToastOptions = {
          type: 'error',
          icon: 'mdi-alert-circle',
          duration: 5 * 1000,
          position: 'top-right'
        };
        const errorMessage = error.response.data
        this.$toasted.error(errorMessage, options);
      }
      this.submitting = false;
    }
  }

  async validateUniqueUsername(username: Nullable<string>) {
    const response: AxiosResponse = await USER_API.validateUniqueUsername(username);
    if (response.data.validation == false) {
      this.inputUsername.valid = false
      this.validation.username = "Nazwa użytkownika nie jest unikalna."
      return false
    }
    this.validation.username = ""
    return true
  }

}
