import { ClientAutocompleteType, Nullable } from "@/models/CustomTypes";

export class CreateUserCmd {
  username: Nullable<string>;

  password: Nullable<string>;

  first_name: Nullable<string>;

  last_name: Nullable<string>;

  email: Nullable<string>;

  clients: Nullable<Array<Nullable<number>>> = new Array<number>();

  constructor(
    username?: Nullable<string>,
    password?: Nullable<string>,
    first_name?: Nullable<string>,
    last_name?: Nullable<string>,
    email?: Nullable<string>,
    clients?: Nullable<Array<Nullable<number>>>
  ) {
    this.username = username;
    this.password = password;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.clients = clients;
  }
}
